import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { config } = error;

    const retry = config.retry ?? 5;

    if (!config || !retry || !error.message.toLowerCase().includes("fetch") || !error.message.toLowerCase().includes("network")) {
      return Promise.reject(error);
    }
    config.retry = retry - 1;
    const retryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.info("Retrying Request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return retryRequest.then(() => axios(config));
  }
);
