import getSymbolFromCurrency from "currency-symbol-map";
import { useAtom } from "jotai";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import toastr from "toastr";
import EmbedController from "../../../../embed/EmbedController";
import { getFinalPrice } from "../../../modules/getFinalPrice";
import { getStepActiveIndex } from "../../../modules/getStepperActiveIndex";
import { CreateScreens } from "../../../screens/CreateScreens";
import { Navigation } from "../../Navigation/Navigation";
import {
  components_state,
  is_experience_loaded_and_revealed,
  items_state,
  products_state,
  update_components_activeId,
  update_items_activeIds,
} from "../../dataManagers/GlobalDataManagers";
import { BuildSection } from "../BuildSection/BuildSection";
import { HeadersBuildSection } from "../BuildSection/HeadersBuildSection";
import ControlsIndicator from "../ControlsIndicator/ControlsIndicator";
import Stepper from "../CustomStepper/CustomStepper";
import { HUD } from "../HUD/HUD";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { SceneLoadingScreen } from "../SceneLoadingScreen/SceneLoadingScreen";
import { SceneLoadingScreenTransparent } from "../SceneLoadingScreenTransparent/SceneLoadingScreenTransparent";
import { ShoppingCart } from "../ShoppingCart/ShoppingCart";
import "./styles.scss";

export const BuilderUI = () => {
  const [isExperienceLoadedAndRevealed] = useAtom(is_experience_loaded_and_revealed);
  // products
  const [productsState] = useAtom(products_state);

  // components
  const [componentsState] = useAtom(components_state);
  const [, setComponentsActiveId] = useAtom(update_components_activeId);
  const [, setComponentsState] = useAtom(components_state);

  // items
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  const [showCartTerms, setShowCartTerms] = useState(false);

  const finalPrice = getFinalPrice(productsState, componentsState, itemsState);

  const handleShare = async () => {
    if (EmbedController.isEmbedded) {
      EmbedController.sendUrlShare();
      EmbedController.setShareUrlCallback((msg) => {
        toastr.info(msg);
      });
    } else {
      if (!navigator) return;
      try {
        const shareData = {
          title: "Thrill Seekers 3D Customizer",
          url: window.location.href,
        };

        await navigator.share(shareData);
        return;
      } catch (error) {
        await navigator.clipboard.writeText(window.location.href);
        toastr.info("Link copied");
      }
    }
  };

  //TODO: move this conditional logic to activeComponentIndex
  const steps = productsState.isPrimed && productsState.array[0].category;
  const activeComponentIndex = productsState.isPrimed && componentsState.isPrimed && getStepActiveIndex(steps, componentsState);

  const subheaderLabel = productsState.isPrimed && `${productsState.activeObj.shopify.displayName}`.trim();

  return (
    <>
      <Container fluid className="BuilderUI p-0">
        {/* UI section on left (desktop) or bottom (mobile) of screen */}
        {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
          <BuildSection
            steps={productsState.array[0].category}
            setShowCartTerms={() => setShowCartTerms(true)}
            componentsStateObj={{
              state: componentsState,
              setActiveId: setComponentsActiveId,
            }}
          >
            <HeadersBuildSection
              brandLogo="/images/brand-logo.png"
              // subheaderLabel={subheaderLabel.length > 40 ? subheaderLabel.substring(0, 40) + "..." : subheaderLabel}
              subheaderLabel={subheaderLabel}
              headerLineImgSrc="/images/bradleyHeaderLine.svg"
              finalPrice={`${getSymbolFromCurrency(productsState.activeObj?.shopify?.currency?.active ?? "USD")}${finalPrice}`}
            />

            {componentsState.activeId !== "shopping_cart" && (
              <>
                <Navigation
                  steps={productsState.array[0].category}
                  setShowCartTerms={() => setShowCartTerms(true)}
                  componentsStateObj={{
                    state: componentsState,
                    setActiveId: setComponentsActiveId,
                  }}
                />
                <Stepper
                  steps={productsState.array[0].category}
                  componentsStateObj={{
                    state: componentsState,
                    setActiveId: setComponentsActiveId,
                  }}
                />
                <h5 className="text-center">{steps[activeComponentIndex].description}</h5>
              </>
            )}

            <CreateScreens productsState={productsState} componentsState={componentsState} itemsStateArray={itemsState.array} />

            {showCartTerms && (
              <ShoppingCart
                cartTermsObj={{
                  state: showCartTerms,
                  setState: setShowCartTerms,
                }}
                productsState={productsState}
                componentsState={componentsState}
                itemsState={itemsState}
              />
            )}
          </BuildSection>
        )}

        {/* UI section overlaying the 3D scene */}
        {/* Uses .shared-scene-sizing to mimic the scene's canvas. */}
        <HUD>
          {/* loading screen used for the initial load - it covers the scene's canvas */}
          <SceneLoadingScreen />
          {/* transparent loader for when assets are loading dynamically but we don't need to cover up whole screen */}
          <SceneLoadingScreenTransparent canBeVisible={isExperienceLoadedAndRevealed} />

          {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
            <>
              <ControlsIndicator />
              <div className={`containerWidgets h-100`}>
                <div className={`innerWrapper pointer-events-all ${isMobile ? "flexMobile" : "flexNormal"}`}>
                  <RectangleButton
                    label="Add To Cart"
                    iconSrc="/images/iconShoppingCart-black.svg"
                    onClickHandler={() => setShowCartTerms(true)}
                    gaObj={{
                      event: "Add-to-Cart-Bradley",
                      addToCartBtnLocation: "Scene",
                    }}
                  />
                  <RectangleButton
                    label={isMobile ? "" : "Share"}
                    iconSrc="/images/share.svg"
                    onClickHandler={() => handleShare()}
                    gaObj={{
                      event: "Share-Thrill",
                      ShareButtonLocation: "Scene",
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </HUD>
      </Container>
    </>
  );
};
