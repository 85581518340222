import EmbedController from "../../embed/EmbedController";
import AlertSlackOfError from "../../monitoring/AlertSlackOfError";

// refreshes (/# URL) or reloads (same URL) the page
export default function refreshExperience(source, sessionId, isSimpleReload, isAutoReload) {
  if (EmbedController.isEmbedded) {
    AlertSlackOfError(
      source,
      `refreshExperience() fired: ${isSimpleReload ? "simpleReload" : "fullRefresh"} by ${isAutoReload ? "code" : "shopper"}`,
      sessionId
    );

    if (isSimpleReload) EmbedController.sendParentReloadRequest();
    else EmbedController.sendParentRefreshRequest();
  } else {
    if (window.location.href?.includes("builder.bradleybaseballgloves"))
      AlertSlackOfError(
        source,
        `refreshExperience() fired: ${isSimpleReload ? "simpleReload" : "fullRefresh"} by ${isAutoReload ? "code" : "shopper"}`,
        sessionId
      );

    if (!isSimpleReload) window.location.hash = "#/";

    window.location.reload();
  }
}
