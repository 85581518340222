import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import EmbedController from "../../../../embed/EmbedController";

export const HeadersBuildSection = ({ brandLogo, headerLabel, headerLineImgSrc, subheaderLabel, finalPrice }) => {
  // custom code thrill code
  const displayBrandLogo = isMobile && EmbedController.isEmbedded ? false : true;
  return (
    <div className="headers d-flex flex-column pt-2">
      {finalPrice && <h5 className="priceInHeader mt-1">{finalPrice} </h5>}
      {headerLabel && <h5 className="text-center pt-1 mb-0">{headerLabel} </h5>}
      {displayBrandLogo && (
        <>
          <img style={{ width: "130px" }} className="mx-auto mb-0" alt="brand logo" src={brandLogo} />
          <img style={{ width: "100px" }} className="mt-1 mx-auto" alt="header underline" src={headerLineImgSrc} />
        </>
      )}
      {subheaderLabel && <h5 className="px-4 mx-auto text-center mt-1 mb-0">{subheaderLabel}</h5>}
    </div>
  );
};

HeadersBuildSection.propTypes = {
  brandLogo: PropTypes.string,
  headerLabel: PropTypes.string,
  headerLineImgSrc: PropTypes.string,
  subheaderLabel: PropTypes.string,
};
