import { useState, useEffect } from "react";
import { useRouter } from "wouter";

import LZString from "lz-string";

const currentLocation = () => {
  if (window.location.hash) return decompressHash(window.location.hash);
  else return "#/";
};

const navigate = (targetHash) => {
  window.location.hash = compressHash(targetHash);
};

export const decompressHash = (hash) => {
  const hashPath = hash.slice(1); // remove #
  return `#${LZString.decompressFromEncodedURIComponent(hashPath)}`;
};
export const compressHash = (hash) => {
  const hashPath = hash.slice(1); // remove #
  return `#${LZString.compressToEncodedURIComponent(hashPath)}`;
};

export const useHashLocation = () => {
  const [loc, setLoc] = useState(currentLocation());

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => setLoc(currentLocation());

    // subscribe to hash changes
    window.addEventListener("hashchange", handler);
    return () => window.removeEventListener("hashchange", handler);
  }, []);

  return [loc, navigate];
};

export const useHashRoute = (pattern, path) => {
  return useRouter().matcher(pattern, path);
};
