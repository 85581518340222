import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { atom, useAtom } from "jotai"
import { Line } from "../Line/Line";
import FormatModalItems from "./FormatModalItems.jsx";
import { getShoppingCartData } from "../../../modules/getShoppingCartData";

export const QuantityAtom = atom(1)
export const FinalNotesAtom = atom("")

export const AdditionalSection = ({ activeModalSectionStateObj, handleCloseModal, productsState, componentsState, itemsState }) => {
  const [quantity, setQuantity] = useAtom(QuantityAtom)
  const [notes, setFinalNotes] = useAtom(FinalNotesAtom)
  const { register, watch } = useForm({
    defaultValues: {
      quantity: quantity,
      finalNotes: notes
    },
  });
  const totalQuantity = watch("quantity");
  const finalNotes = watch("finalNotes")
  useEffect(() => {
    setQuantity(totalQuantity)
  }, [totalQuantity])

  useEffect(() => {
    setFinalNotes(finalNotes)
  }, [finalNotes])

  const [cartSubmissionObj] = getShoppingCartData(productsState, componentsState, itemsState, totalQuantity, finalNotes);



  return (
    <>
      <Modal.Header>
        <Modal.Title className="mx-auto text-uppercase">Add To Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <img className="mx-auto brandImage" src="/images/brand-logo.avif" alt="brand" />
        <h4 className="font-weight-bold mt-2">
          Review Your <span className="text-decoration-underline"> Final</span> Options
        </h4>
        <div className="d-flex align-items-start justify-content-center">
          <img className="mx-auto warningIcon mt-2" src="/images/iconWarning.png" alt="warning" />
          <h5 className="text-danger mt-2 text-center">No changes or cancellations can be made once order is submitted</h5>
        </div>
        <p className="text-warning text-decoration-underline">Custom Seat Cover: {productsState?.activeObj?.shopify?.displayName}</p>
        <FormatModalItems cartSubmissionObj={cartSubmissionObj} />
        <Line color="#AFAFAF" height="1px" width="85%" />
        <form>
          <div className="d-flex flex-column my-3">
            <h4 className="my-3">Final Order Notes</h4>
            <textarea className="mx-auto" cols={35} rows={4} {...register("finalNotes")} placeholder="Anything you'd like to mention?" />
          </div>

          <Line color="#AFAFAF" height="1px" width="85%" />

          <h4 className="font-weight-bold mt-2">Quantity</h4>
          <div className="d-flex justify-content-around my-3">
            <label htmlFor="quantity">
              <input
                min="0"
                {...register("quantity", { required: true, min: 0 })}
                type="number"
                name="quantity"
                className="form-number-input mx-1"
                id="quantity"
              />
            </label>
          </div>

          <div className="d-flex action-buttons justify-content-center mt-4">
            <button type="button" className="mx-2 px-2" style={{ backgroundColor: "grey" }} onClick={handleCloseModal}>
              Close
            </button>
            <button className="mx-2 px-2 submitBtn" onClick={() => activeModalSectionStateObj.setState("terms-conditions")}>
              Continue
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
};