import { useEffect, useState, useRef } from "react";
import { animated } from "react-spring";
import { isMobile } from "react-device-detect";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export default function ControlsIndicator() {
  const [isIconVisible, setIsIconVisible] = useState(false);
  const hasInidicatorBeenShown = useRef(false);
  const timeoutId = useRef();

  // const orbitAnimation = useSpring({
  //   loop: true,
  //   from: { transform: 'rotateZ(0deg) translateX(50px) rotate(0deg)' },
  //   to: { transform: 'rotateZ(360deg) translateX(50px) rotate(-360deg)' },
  //   config: {
  //     duration: 2000
  //   }
  // })

  // const rotateAnimation = useSpring({
  //   loop: { reverse: true },
  //   from: { transform: "translate(30px,0)" },
  //   to: [{ transform: "translate(-30px,0)" }, { transform: "translate(30px,0)" }]
  // })

  // const scaleAnimation = useSpring({
  //   loop: { reverse: true },
  //   from: { transform: "scale(1)" },
  //   to: [{ transform: "scale(1.05)" }, { transform: "scale(1)" }],
  //   config: {
  //     duration: 1000
  //   },
  // })

  useEffect(() => {
    // setup listener to know when scene is revealed
    document.addEventListener("SceneIsBeingRevealed", revealIcon);

    // setup listeners to know when scene is clicked or touched
    let sceneCanvas = document.getElementById("builder-scene-canvas-container").children[0];
    if (sceneCanvas) sceneCanvas.addEventListener("mousedown", handleMouseDown);
    if (sceneCanvas) sceneCanvas.addEventListener("touchstart", handleMouseDown);

    return () => {
      document.removeEventListener("SceneIsBeingRevealed", revealIcon);
      sceneCanvas.removeEventListener("mousedown", handleMouseDown);
      sceneCanvas.removeEventListener("touchstart", handleMouseDown);
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  function revealIcon() {
    if (hasInidicatorBeenShown.current) return;
    hasInidicatorBeenShown.current = true;

    timeoutId.current = setTimeout(function () {
      setIsIconVisible(true);
    }, 3000);
  }

  function handleMouseDown() {
    setIsIconVisible(false);
  }

  return (
    <>
      {isIconVisible && (
        <ImgWithFallback
          className="ControlsIndicator"
          src={isMobile ? "/images/controls_indicator/controls_mobile.webp" : "/images/controls_indicator/controls_desktop.webp"}
          fallback={isMobile ? "/images/controls_indicator/controls_mobile.png" : "/images/controls_indicator/controls_desktop.png"}
          alt="Controls Indicator"
        />
      )}
    </>
  );
}
