import { Float, PresentationControls } from "@react-three/drei";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
// import { traverse } from 'object-traversal';
// import sift from 'sift';
import { ResizeObserver } from "@juggle/resize-observer";
// import useMediaQuery from '@mui/material/useMediaQuery';
import EnvironmentController from "./EnvironmentController";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import AssetSystem3d, { useAssetLoader, useGLTFLoader } from "../dataManagers/AssetSystem3d";
// import { useActiveItem } from '../../modules/useActiveItem';
import { useAtom } from "jotai";
import { isMobile } from "react-device-detect";
import {
  camera_lookAt,
  camera_starting_position,
  canvas_base64,
  components_state,
  // loading_state,
  // update_loading_count,
  // is_experience_loaded_and_revealed,
  customTextureAtomsObj,
  items_state,
  model_rotation_override,
  model_scale,
  model_starting_position,
  model_starting_rotation,
  products_state,
} from "../dataManagers/GlobalDataManagers";
import { CanvasCompositor } from "./CanvasCompositor/CanvasCompositor";
// import { set } from 'lodash';
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import PanCameraFromCursorControls from "./PanCameraFromCursorControls";
import { ScrollToScaleControls } from "./scrollToScaleControls";

// var TWEEN = require('tween.js');

export default function Scene() {
  const [productsState] = useAtom(products_state);
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  const [cameraStartPos] = useAtom(camera_starting_position);

  return (
    <>
      {/* handles compositing the canvas(es) for custom textures */}
      {itemsState.isPrimed &&
        productsState.activeObj?.customTextureInfo?.map((customTextureInfoObj, i) => {
          return (
            <CanvasCompositor
              key={i}
              applicableComponentId={customTextureInfoObj.applicableComponentId}
              componentColorController={customTextureInfoObj.componentColorController}
              textureAtom={customTextureAtomsObj[customTextureInfoObj.atomName]}
            />
          );
        })}

      {/* canvas that will hold a screenshot of the main three.js canvas for the shopping cart */}
      <canvas
        id="screenshot_canvas"
        style={{ display: "none" }}
        // style={{position: "absolute", top: "0", left: "0", zIndex: "10000", border: "2px solid red"}}
        width={800}
        height={800}
      ></canvas>

      {/* canvas used to capture color data from color textures */}
      <canvas id="colorPicker_canvas" style={{ display: "none" }} width={1} height={1}></canvas>

      {/* three scene's canvas */}
      <Canvas
        id="builder-scene-canvas-container"
        className="shared-scene-sizing builder-scene-canvas-container"
        camera={{
          position: cameraStartPos,
          rotation: [0, 0, 0],
          fov: isMobile ? 30 : 30,
          near: 0.1,
          far: 100,
        }}
        gl={{ physicallyCorrectLights: true }}
        flat={true} // sets renderer.toneMapping = THREE.NoToneMapping
        dpr={[1, 2.5]} // handles resolution of canvas
        shadows={{ enabled: true, type: THREE.PCFShadowMap }}
        resize={{ polyfill: ResizeObserver }}
      >
        <PanCameraFromCursorControls isMobile={isMobile} />

        {/* <OrbitControls
        makeDefault
        autoRotate={false}
        enableKeys={false}
        enablePan={false}
        enableRotate={false}
        // enableRotate={true}
        enableZoom={true}
        minDistance={0.2}
        maxDistance={1.8}
        target={lookAtTarget}
        enableDamping={true}
        rotateSpeed={isMobile ? 0.7 : 0.35}
        // minAzimuthAngle={0} // horizontal
        // maxAzimuthAngle={0} // horizontal
        minPolarAngle={0} // vertical (TOP)
        maxPolarAngle={2.7} // vertical (BOTTOM)
      /> */}

        <AssetSystem3d>
          {/* environment assets, lighting, etc.  */}
          <EnvironmentController />

          {/* Root of configuration experience */}
          {productsState.isPrimed && itemsState.isPrimed && <ExperienceManager productsState={productsState} itemsState={itemsState} />}
        </AssetSystem3d>
      </Canvas>
    </>
  );
}

function ExperienceManager({ productsState }) {
  const { gl, scene, camera } = useThree();

  // whenever scene is revealed,
  useEffect(() => {
    primeIntroAnimation();
    document.addEventListener("SceneIsBeingRevealed", handleSceneReveal);
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", handleSceneReveal);
    };
  }, []);
  function handleSceneReveal() {
    executeIntroAnimation();
  }

  function primeIntroAnimation() {
    camera.position.set(-0.5, 1.6, -1.5);
  }
  function executeIntroAnimation() {
    document.dispatchEvent(new CustomEvent("executeIntroAnimation", { detail: { duration: 4000 } }));
  }

  /**
   *
   * Convert the scene's canvas to a base64 image that can be sent to the client's shopping cart
   *
   */

  useEffect(() => {
    camera.layers.enableAll(); // make sure the main camera is displaying all layers
    document.addEventListener("ScreenshotCanvasForCartImage", convertCanvasToBase64);
    return () => document.removeEventListener("ScreenshotCanvasForCartImage", convertCanvasToBase64);
  }, []);

  const [, setCanvasBase64] = useAtom(canvas_base64);
  const [, setModelScale] = useAtom(model_scale);
  const [, setModelRotation] = useAtom(model_rotation_override);
  const [modelStartingRotation] = useAtom(model_starting_rotation);
  const [modelPos] = useAtom(model_starting_position);
  const photoCamera_ref = React.useRef(new THREE.PerspectiveCamera(45, 1, 0.1, 10));

  async function convertCanvasToBase64() {
    let sceneCanvas = gl.domElement;
    let targetCanvas = document.getElementById("screenshot_canvas");

    let takeNumber = 0;
    setModelRotation([modelStartingRotation[0], modelStartingRotation[1] + Math.random() * 0.001, modelStartingRotation[2]]);
    setModelScale(1);
    await delay(1500); // wait for rotation to finish

    setupScene(takeNumber);
    renderShotToCanvas(takeNumber, sceneCanvas, targetCanvas);

    // save as base64
    let base64 = targetCanvas.toDataURL("image/png");
    setCanvasBase64(base64);
    console.log("convertCanvasToBase64", base64.slice(0, 50));
  }

  function setupScene(takeNumber) {
    const cameraPostions = productsState.activeObj.screenshotCameraPostiions;

    photoCamera_ref.current.position.set(...cameraPostions[takeNumber]);

    photoCamera_ref.current.lookAt(modelPos[0], modelPos[1] - 0.1, modelPos[2]);

    photoCamera_ref.current.layers.disable(1); // hides environment
  }

  function renderShotToCanvas(takeNumber, sceneCanvas, targetCanvas) {
    // set drawing position (quadrant) on canvas
    let xPos, yPos;
    if (takeNumber === 0) {
      xPos = 0;
      yPos = 0;
    } else if (takeNumber === 1) {
      xPos = targetCanvas.width / 2;
      yPos = 0;
    } else if (takeNumber === 2) {
      xPos = 0;
      yPos = targetCanvas.height / 2;
    } else if (takeNumber === 3) {
      xPos = targetCanvas.width / 2;
      yPos = targetCanvas.height / 2;
    }

    gl.render(scene, photoCamera_ref.current);

    let ctx = targetCanvas.getContext("2d");

    if (takeNumber === 0) ctx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);

    ctx.drawImage(sceneCanvas, 0, 0, sceneCanvas.width, sceneCanvas.height, xPos, yPos, targetCanvas.width, targetCanvas.height);
    // use this if screenshot will have 4 quadrants
    // ctx.drawImage(sceneCanvas, 0, 0, sceneCanvas.width, sceneCanvas.height, xPos, yPos, targetCanvas.width / 2, targetCanvas.height /2);
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  /**
   * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   */

  return (
    <>
      <Suspense fallback={null}>
        <ModelController productsState={productsState} />
      </Suspense>

      <PreloadTextures />
    </>
  );
}

function PreloadTextures() {
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);
  const getAsset = useAssetLoader();
  const hasLoaded = useRef(false);

  preloadTextures();

  async function preloadTextures() {
    if (hasLoaded.current || !componentsState.isPrimed || !itemsState.isPrimed) return;
    hasLoaded.current = true;

    let texturePromises = [];

    itemsState.activeIds &&
      Object.entries(itemsState.activeIds).map(([componentId]) => {
        // find respective component obj
        let componentObj = componentsState.array.find((component) => component._id === componentId);
        if (componentObj?.excluded) return null;

        // find respective item obj
        let item = itemsState.activeObjs[componentId];
        if (!item?.material_obj?.properties) return null;

        if (componentObj?.characteristics?.includes("material-component")) {
          Object.entries(item.material_obj.properties).forEach(([key, value]) => {
            if (key.toLowerCase()?.includes("map") && value) {
              let texturePromise = new Promise(async (resolve) => {
                await getAsset(value);
                resolve();
              });
              texturePromises.push(texturePromise);
            }
          });
        }
      });

    await Promise.all(texturePromises);
  }

  return null;
}

function ModelController({ productsState }) {
  // need to know when experience is loaded and revealed
  // const [isExperienceLoadedAndRevealed] = useAtom(is_experience_loaded_and_revealed);

  // const [, updateLoadingState] = useAtom(update_loading_count);
  // const getAsset = useAssetLoader();

  // load the model
  // const modelContainer_ref = useRef();
  const gltf = useLoader(useGLTFLoader, productsState?.activeObj?.modelSrc);

  // when gltf is updated
  useEffect(() => {
    addShadowToAllMesh(gltf.scene, false);
    dispatchLoadedEvent();
  }, [gltf]);

  function addShadowToAllMesh(scene, shouldReceiveShadow) {
    scene.traverse((node) => {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = shouldReceiveShadow;
      }
    });
  }

  // update TWEEN for tweens to be used in the experience
  // useFrame(() => {
  //   TWEEN.update();
  // })

  return (
    <>
      {/* handle's configuration logic for the model */}
      <ConfigurationManager gltf={gltf} />

      {/* handle's logic for shopper to interact with the model */}
      <InteractionManager>
        <primitive object={gltf.scene} />
      </InteractionManager>

      {/* CUSTOM CODE: dynamically handle's custom canvas textures */}
      {productsState.activeObj.customTextureInfo.map((customTextureInfo, index) => {
        return <CustomTextureManager key={index} productGltf={gltf} customTextureInfo={customTextureInfo} />;
      })}
    </>
  );
}

function CustomTextureManager({ productGltf, customTextureInfo }) {
  const [customTextureObj] = useAtom(customTextureAtomsObj[customTextureInfo.atomName]);
  const textureType = customTextureObj?.type;
  const [itemsState] = useAtom(items_state);
  const getAsset = useAssetLoader();

  const targetMesh = useMemo(() => {
    return findMeshByMaterial(customTextureInfo.materialName, productGltf.scene);
  }, [productGltf, customTextureObj]);
  const targetMaterial = targetMesh?.material;

  // const targetColor = useMemo(() => {
  // 	return itemsState.activeObjs[customTextureInfo.componentColorController]?.material_obj.constructor.color;
  // }, [itemsState.activeObjs[customTextureInfo.componentColorController]?._id]);

  // CUSTOM CODE: get the color from an image
  // ___________________________________________________________________
  const [targetColor, setTargetColor] = useState("rgb(255, 255, 255)");
  // const ctx = useMemo(() => {
  //   return document.getElementById("colorPicker_canvas").getContext("2d", { willReadFrequently: true });
  // }, []);
  // useEffect(() => {
  // async function loadColorFromImage() {
  //   let img = await loadImageAsync(itemsState.activeObjs[customTextureInfo.componentColorController]?.imageSrc);
  //   ctx.drawImage(img, 0, 0);
  //   const colorData = ctx.getImageData(0, 0, 1, 1).data;
  //   setTargetColor(`rgb(${colorData[0]}, ${colorData[1]}, ${colorData[2]})`);
  // }
  // loadColorFromImage();
  // }, [itemsState.activeObjs[customTextureInfo.componentColorController]?._id]);
  // ___________________________________________________________________

  // patch the fragment shader to clip at edges instead of repeat
  useEffect(() => {
    targetMesh.material.onBeforeCompile = (shader) => {
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <map_fragment>",
        `
        #ifdef USE_MAP
          if (vUv.x > 1.0 || vUv.x < 0.0 || vUv.y > 1.0 || vUv.y < 0.0) discard;
          vec4 texelColor = texture2D( map, vUv );
          diffuseColor *= texelColor;
        #endif
        `
      );
    };
  }, [targetMesh]);

  useEffect(() => {
    updateTexture();
  }, [customTextureObj]);

  // useEffect(() => {
  //   updateColor();
  // }, [targetColor]);

  // function updateColor() {
  //   if (targetMesh.material && targetColor) targetMesh.material.color.set(targetColor);
  // }

  async function updateTexture() {
    if (!customTextureObj) return;

    let mapTexture = await getAsset("canvas", customTextureObj.canvas);

    mapTexture = sizeTextureForMesh(targetMesh, customTextureObj, mapTexture);

    // apply textures to material
    let oldMap = targetMaterial.map;
    targetMaterial.map = mapTexture;
    targetMaterial.transparent = true;
    targetMaterial.opacity = 1;

    // set color of material
    targetMaterial.color.set(textureType === "TEXT" ? targetColor : "#ffffff");

    // mark textures and mat for update
    mapTexture.needsUpdate = true;
    targetMaterial.needsUpdate = true;

    if (oldMap) oldMap.dispose();
  }

  /**
   * resize texture depending upon the mesh's aspect ratio
   */
  function sizeTextureForMesh(mesh, textureDataObj, texture) {
    let uvAspectRatio = customTextureInfo.geometry_aspectRatio_data.width / customTextureInfo.geometry_aspectRatio_data.height;
    let repeatXY;

    // Text is wider than geometry so scaling was applied by CanvasCompositor
    if (textureDataObj.srcImgAspectRatio > uvAspectRatio) repeatXY = 1;
    // Text is taller than geometry so scale down so the y-axis stretches to UV bounds (while maintaining aspect ratio)
    else repeatXY = Math.min(uvAspectRatio, uvAspectRatio * (1 / textureDataObj.srcImgAspectRatio));

    texture.repeat.set(repeatXY, repeatXY);

    let offset = ((repeatXY - 1) / 2) * -1;
    texture.offset.x = offset;
    texture.offset.y = offset;

    return texture;
  }

  function calcAspectRatio(boundingBox) {
    // width / height
    return (boundingBox.max.x - boundingBox.min.x) / (boundingBox.max.y - boundingBox.min.y);
  }

  return null;
}

function InteractionManager({ children }) {
  // const [componentsState] = useAtom(components_state);
  // const [itemsState] = useAtom(items_state);
  const [modelRotation] = useAtom(model_starting_rotation);

  const rotationParent_ref = useRef();
  const rotationRoot_ref = useRef();
  useEffect(() => {
    rotationRoot_ref.current = rotationParent_ref.current.children[0];
  }, []);

  /**
   * handles rotating the model to focus on newly chosen item
   */
  const [targetRotation, setTargetRotation] = useState(modelRotation); // can adjust the y-axis rotation but adjusting the x-axis rotation screws up the presentation controls
  const [rotationOverride] = useAtom(model_rotation_override);
  useEffect(() => {
    if (rotationOverride) setTargetRotation(rotationOverride);
  }, [rotationOverride]);
  // useEffect(() => {
  //   if (componentsState.activeObj?.characteristics?.includes('focus-component')) {
  //     setTargetRotation(() => {
  //       let targetRotationArray = [...componentsState.activeObj.focusData.rotation];

  //       targetRotationArray = targetRotationArray.map((targetRadians, index) => {
  //         let translator = ["x", "y", "z"];
  //         let exactRotation = rotationRoot_ref.current.rotation[translator[index]];
  //         let exactTurns = exactRotation / (Math.PI * 2);
  //         let fullTurns = Math.round(exactTurns);

  //         let rotationsToTest = [
  //           (fullTurns - 1) * (Math.PI * 2) + targetRadians,
  //           fullTurns * (Math.PI * 2) + targetRadians,
  //           (fullTurns + 1) * (Math.PI * 2) + targetRadians
  //         ];
  //         // console.log('rotationsToTest', rotationsToTest)

  //         let rotationDeltas = rotationsToTest.map((rotation) => {
  //           return Math.abs(exactRotation - rotation)
  //         })
  //         // console.log('rotationDeltas ', rotationDeltas)

  //         let closestRotation;
  //         let smallestDelta = 100;
  //         rotationDeltas.forEach((rotationDelta, index) => {
  //           if (rotationDelta < smallestDelta) {
  //             smallestDelta = rotationDelta;
  //             closestRotation = rotationsToTest[index];
  //           }
  //         })

  //         return closestRotation
  //       })

  //       // need to barely increment value so state change registers
  //       return [targetRotationArray[0] + Math.random() * 0.001, targetRotationArray[1] + Math.random() * 0.001, targetRotationArray[2] + Math.random() * 0.001]
  //     });
  //   }
  // }, [itemsState.activeIds])

  /**
   *
   *
   * handles scaling the model when user "zooms"
   *
   *
   */

  const camera = useThree(({ camera }) => camera);
  const gl = useThree(({ gl }) => gl);

  const controls_ref = useRef();

  const [modelScale, setModelScale] = useAtom(model_scale);
  const [cameraLookAt] = useAtom(camera_lookAt);
  const [modelPosition] = useAtom(model_starting_position);

  useEffect(() => {
    setupScrollToScaleControls();
  }, []);
  function setupScrollToScaleControls() {
    controls_ref.current = new ScrollToScaleControls(camera, gl.domElement, setModelScale, 1, 2.5);
    controls_ref.current.target.set(...cameraLookAt); // lookAt is mainly handled by PanCameraFromCursorControls but set a starting target
    controls_ref.current.enableRotate = false;
    controls_ref.current.enablePan = false;
    controls_ref.current.update();
  }
  // useFrame(() => {
  // controls_ref.current.update();
  // })

  return (
    <group>
      {/* needs this group with weird scale and rotation to make the presentation controls work properly */}
      <group ref={rotationParent_ref} position={modelPosition} rotation={[0, 0, 0]} scale={[-modelScale, -modelScale, modelScale]}>
        <PresentationControls
          global={true} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on dragx
          snap={false} // Snap-back to center (can also be a spring config)
          speed={isMobile ? 2 : 1.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={targetRotation} // Default rotation
          polar={[-0.5, 0.5]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 150, friction: 50 }} // Spring config
        >
          <Float
            floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange, defaults to 1
          >
            {/* the model */}
            {children}
          </Float>
        </PresentationControls>
      </group>
    </group>
  );
}

function ConfigurationManager({ gltf }) {
  const [componentsState] = useAtom(components_state);
  const [productsState] = useAtom(products_state);

  const [itemsState] = useAtom(items_state);
  return (
    <>
      {/* iterate through all the active items */}
      {itemsState.activeIds &&
        Object.entries(itemsState.activeIds).map(([componentId]) => {
          // find respective component obj
          let componentObj = componentsState.array.find((component) => component._id === componentId);
          // find respective item obj
          let itemObj = itemsState.activeObjs[componentId];
          if (!itemObj || (itemObj && Object.keys(itemObj).length === 0)) {
            console.error("EMPTY ITEM OBJ", componentId, itemObj);
            AlertSlackOfError("ConfigurationManager", `EMPTY ITEM OBJ: ${componentId}`);
          }

          // CUSTOM CODE: removes front patch or ribs from 3D scene whenever they are supposed to be unavailable for this product
          if (productsState.activeObj.shopify["omit-front-patch"]) {
            if (["patch-material-front", "custom-patch-front"].includes(componentObj._id)) {
              if (componentObj?.characteristics?.includes("material-component"))
                return <MaterialController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
              else if (componentObj?.characteristics?.includes("mesh-component"))
                return <MeshController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
            }
          }
          if (productsState.activeObj.shopify["omit-ribs"]) {
            if (["rib-type"].includes(componentObj._id)) {
              if (componentObj?.characteristics?.includes("material-component"))
                return <MaterialController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
              else if (componentObj?.characteristics?.includes("mesh-component"))
                return <MeshController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
            }
          }
          if (componentObj?.excluded) return null;

          // check for 'material' or 'mesh' component so we know which controllers to instantiate
          if (componentObj?.characteristics?.includes("material-component"))
            return <MaterialController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
          else if (componentObj?.characteristics?.includes("mesh-component"))
            return <MeshController item={itemObj} component={componentObj} modelScene={gltf.scene} key={componentId} />;
        })}
    </>
  );
}

/**
 * Used when mesh need to be swapped out according to item choice
 * Associated with itemGroups in the model
 */
function MeshController({ item, component, modelScene }) {
  const targetNode = useMemo(() => {
    return modelScene.getObjectByName(component.nodeTargetName);
  }, []);
  useEffect(() => {
    handleStichingVisibility();
  }, [item, modelScene]);

  const handleStichingVisibility = () => {
    switch (item._id) {
      case "no-ribs":
        const shadow = findMaterial("shadow", modelScene);
        shadow.visible = false;
        modelScene.traverse((node) => {
          if (node.name === "stitching") {
            node.visible = false;
          }
          if (node.name === "stitching_noRibVersion") {
            node.visible = true;
          }
        });
        break;
      case "single-material-ribs":
      case "multi-material-ribs":
        if (item._id === "single-material-ribs") {
          const shadow = findMaterial("shadow", modelScene);
          shadow.visible = true;
        }
        modelScene.traverse((node) => {
          if (node.name === "stitching") {
            node.visible = true;
          }
          if (node.name === "stitching_noRibVersion") {
            node.visible = false;
          }
        });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!targetNode) return;

    targetNode.traverse((node) => {
      // criteria to be visible: itemGroups, item children, item node that is active
      if (node.name?.includes("itemGroup") || !node.name?.includes("item") || node.name === item.nodeTargetName) {
        node.visible = true;
      }

      // criteria to be hidden: inactive 'item' nodes
      else {
        node.visible = false;
      }
    });
  }, [targetNode, item]);

  return null;
}

/**
 * Used when materials will be swapped out according to item choice
 */
function MaterialController({ item, component, modelScene }) {
  // const [, updateLoadingState] = useAtom(update_loading_count);

  // const applicableItem = useActiveItem(itemContainerId);
  const getAsset = useAssetLoader();

  const targetMaterial = useMemo(() => {
    return findMeshByMaterial(component.materialTargetName, modelScene)?.material;
  }, []);

  // handles updating the material
  useEffect(() => {
    if (!targetMaterial || !item?.material_obj) return;

    // CUSTOM CODE
    // --------------------------------------------------
    if (item?._id === "hidden-rib") {
      handleHiddenRib();
      return;
    } else if (component._id.includes("ribs-material") && component._id !== "ribs-material-all") {
      const stitchingMat = findStitchingMat();
      stitchingMat.userData.hiddenRib = false;
    }
    // --------------------------------------------------

    updateMaterial(targetMaterial, item);
  }, [targetMaterial, item]);

  // CUSTOM CODE
  function handleHiddenRib() {
    targetMaterial.visible = false;
    targetMaterial.needsUpdate = true;
    // hide all shadows
    const shadow = findMaterial("shadow", modelScene);
    shadow.visible = false;
    shadow.needsUpdate = true;
    // hide stitching
    const stitchingMat = findStitchingMat();
    if (stitchingMat) {
      stitchingMat.userData.hiddenRib = true;
      stitchingMat.visible = false;
      stitchingMat.needsUpdate = true;
    }

    // dispatchLoadedEvent();
  }

  function findStitchingMat() {
    const ribMaterialName = targetMaterial.name;
    const stitchingMaterialName = ribMaterialName.replace("-material", "-stitching-material");
    return findMaterial(stitchingMaterialName, modelScene);
  }

  async function updateMaterial(targetMaterial, item) {
    let newMat = createMaterial(item.material_obj);
    // handleMaterialTextures(targetMaterial, newMat);

    newMat = await applyMaterialProperties(getAsset, item.material_obj.properties, newMat);

    let matName = targetMaterial.name;

    targetMaterial.copy(newMat);

    targetMaterial.name = matName;
    // targetMaterial.side = THREE.DoubleSide;

    // CUSTOM CODE:
    // --------------------------------------------------
    handleStitchingMaterials(targetMaterial, item);
    // --------------------------------------------------

    targetMaterial.needsUpdate = true;

    newMat.dispose();

    dispatchLoadedEvent();
  }

  // CUSTOM CODE:
  // --------------------------------------------------
  function handleStitchingMaterials(targetMaterial, item) {
    const multiRibStitchingNames = [
      "rib-1-stitching-material",
      "rib-2-stitching-material",
      "rib-3-stitching-material",
      "rib-4-stitching-material",
      "rib-5-stitching-material",
      "rib-6-stitching-material",
    ];

    if (item._id === "matching-color-stitching") {
      targetMaterial.visible = false; // default stitching materials
      multiRibStitchingNames.forEach((stitchingName) => {
        const stitchingMat = findMaterial(stitchingName, modelScene);
        if (stitchingMat) stitchingMat.visible = false;
      });
    } else if (item._id.includes("stitching")) {
      multiRibStitchingNames.forEach((stitchingName) => {
        const stitchingMat = findMaterial(stitchingName, modelScene);
        if (stitchingMat && !stitchingMat.userData.hiddenRib) {
          const userData = stitchingMat.userData;
          stitchingMat.copy(targetMaterial);
          stitchingMat.name = stitchingName;
          stitchingMat.visible = true;
          stitchingMat.userData = userData;
        }
      });
    }
  }
  // --------------------------------------------------

  return null;
}

function createMaterial(materialObj) {
  switch (materialObj.type) {
    case "MeshStandardMaterial":
      return new THREE.MeshStandardMaterial(materialObj.constructor);
      break;

    case "MeshBasicMaterial":
      return new THREE.MeshBasicMaterial(materialObj.constructor);
      break;

    default:
      return new THREE.MeshStandardMaterial(materialObj.constructor);
      break;
  }
}

// TODO: might need to be udpated for Thrill Seekers
// needed because we don't define any textures in our data but want to carry them over from the oldMat to newMat
// function handleMaterialTextures(oldMaterial, newMaterial) {
//   if (oldMaterial.map) newMaterial.map = oldMaterial.map;
//   if (oldMaterial.transparent) newMaterial.transparent = true;
//   if (oldMaterial.normalMap) {
//     newMaterial.normalMap = oldMaterial.normalMap;
//     newMaterial.normalScale = oldMaterial.normalScale;
//   }
// }

// function AnimationController({animationClips, animationName, meshToAnimate}) {

//   // setup Mixer
//   const mixer = useMemo(() => {
//     return new THREE.AnimationMixer(meshToAnimate);
//   }, [meshToAnimate])
//   useFrame((state, dt) => mixer && mixer.update(dt))

//   // setup Action
//   const action = useMemo(() => {
//     let a;
//     animationClips.forEach((clip) => {
//       if (clip.name === animationName)
//         a = mixer.clipAction(clip);
//     })
//     return a;
//   }, [animationClips, animationName, meshToAnimate])

//   // animation controls

//   function playAnimation(action, secDuration) {
//     if (!action) return;
//     action.clampWhenFinished = true;
//     action.setLoop(THREE.LoopOnce);
//     if (!secDuration) action.setEffectiveTimeScale(1);
//     else action.setDuration(secDuration);
//     action.paused = false;
//     action.play();
//   }

//   function pauseAnimation(action) {
//     if (!action) return;
//     action.paused = true;
//   }

//   function setTime(action, newTime) {
//     if (!action) return;
//     action.time = newTime;
//     action.paused = true;
//     action.play();
//   }

//   return null;

// }

/**
 *
 * helper functions
 *
 */

// function applyModsToObject3D(object3D, mods, specificModelScene, rootModelScene) {
//   // iterate through mods & apply mod to the object3D
//   Object.entries(mods).forEach(([key, value]) => {
//     // SPECIAL CASE: copyMaterial means we need to update material via copy
//     if (key === 'copyMaterial') {
//       let matToUpdate = findMeshByMaterial(value.to, specificModelScene).material;
//       let matNameToKeep = `${matToUpdate.name}`;
//       let matToCopy = findMeshByMaterial(value.from, rootModelScene).material;
//       matToUpdate.copy(matToCopy);
//       matToUpdate.name = matNameToKeep;
//     }
//     // SPECIAL CASE: replaceMaterial means we need to update a mesh to use a different material
//     if (key === 'replaceMaterial') {
//       let sourceMat = findMeshByMaterial(value.keeper, rootModelScene).material;
//       let meshToReplace = findMeshByMaterial(value.replace, specificModelScene);
//       if (meshToReplace) meshToReplace.material = sourceMat;
//     }
//     // CASE: arrays turn into vectors
//     else if (Array.isArray(value)) {
//       object3D[key].fromArray(value);
//     }
//     // pass in new value as is
//     else {
//       object3D[key] = value;
//     }
//   });
// }

async function applyMaterialProperties(getAsset, material_props, material) {
  let texturePromises = [];

  Object.entries(material_props).forEach(([key, value]) => {
    // textures
    if (key.toLowerCase()?.includes("map")) {
      let texturePromise = new Promise(async (resolve) => {
        let texture = await getAsset(value);
        material[key] = texture;
        if (key?.includes("metal")) material["roughnessMap"] = texture;
        else if (key?.includes("rough")) material["metalnessMap"] = texture;
        resolve();
      });
      texturePromises.push(texturePromise);
    }

    // properties that need some preperation

    // arrays turn into vectors
    else if (Array.isArray(value)) {
      material[key].fromArray(value);
    }

    // regular material properties
    else {
      material[key] = value;
    }
  });

  await Promise.all(texturePromises);

  material.needsUpdate = true;
  return material;
}

function findMeshByMaterial(materialName, scene) {
  let mesh;
  scene.traverse((node) => {
    if (node.isMesh && node.material.name == materialName) mesh = node;
  });
  return mesh;
}

function findMaterial(materialName, scene) {
  let material;
  scene.traverse((node) => {
    if (node.isMesh && node.material.name == materialName) material = node.material;
  });
  return material;
}

function dispatchLoadedEvent() {
  document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
}

function delay(milliseconds) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, milliseconds);
  });
}
